import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CardSelection from '../components/card-selection';
import { setBreadcrumbs, showSidebar } from '../store/actions/app';
import { DisplayText } from '../components/display-text';
const HomeContainer = styled.div`
    padding: 30px;
`;

const HomeTitle = styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

const HomeSubTitle = styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

const HomeNotes = styled.div`
    text-align: left;

    /* &::before {
        content: "Note: ";
        font-weight: 700;
    } */

    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0;
`;

const UrgentMessage = styled.div`
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        text-decoration: underline;
        font-style: italic;
        width: 80%;
        font-size: 1rem;
    }
`

class Home extends Component {
    componentDidMount() {
        this.props.clearBreadcrumbs();
        this.props.toggleSidebar('');
    }

    render() {
        const { profile } = this.props;
        // console.log(this.props);
        return (
            <HomeContainer>
                <HomeTitle>
                    <DisplayText size="medium" color="#333">Welcome to HSBC Credit Card Rewards Programme</DisplayText>
                </HomeTitle>
<HomeTitle>
                    <DisplayText size="medium" color="#333">
    <img src="https://www.creditcardrewards.hsbc.co.in/images/catalogue/BannerNov24.jpg" alt="img" style={{maxWidth : '100%'}}/>
  </DisplayText>
                </HomeTitle>
        
                <HomeSubTitle>
                    <DisplayText color="primary">Select your Credit Card Type to Proceed</DisplayText>
                </HomeSubTitle>
                <CardSelection autoSelect={false} />
                <HomeNotes>
                    <DisplayText size="small">
                    {profile ? 
                        (
                            <p>
                                <ul type="disc">
                                <li>HSBC Credit Card Rewards Programme is applicable on select HSBC Credit Cards products only.</li>
                                <li>Redemptions against the rewards catalogue are allowed on Primary cards only. Add-on credit cards will not be displayed. If any of your credit card(s) are not displayed here, please contact HSBC Phone Banking.</li>
                                <li>“Club and Redeem” option is currently not available on the online catalogue. Please call HSBC PhoneBanking in case you want to combine reward points of multiple HSBC Credit Cards to place the redemption request.</li>
                                <li>Handling charges of INR 50/- per redemption will be levied for HSBC Classic, Gold, Smart Value, Platinum and Advance Platinum Credit Cards, except on donation requests.</li>
                                <li>To know more about the HSBC Credit Cards rewards program, click <a href="https://www.hsbc.co.in/credit-cards/rewards/" target="_blank">https://www.hsbc.co.in/credit-cards/rewards/</a></li>
                                </ul>
                            </p>
                        ) : 
                        (
                            <p>
                                <ul type="disc">
                                <li>The redemption of any product in this catalogue is subject to The Hongkong and Shanghai Banking Corporation Limited prevailing Terms and Conditions governing the Bank's Rewards Programme.</li>
                                <li>In particular, please note that the Bank makes no warranty whatsoever as to the quality of fitness for any particular purpose of any product shown in this catalogue. Any dispute you may have concerning any product shall be settled with the relevant service establishment and/or supplier which supplied the product. The Bank will bear no responsibility for resolving such disputes or for the dispute itself.</li>
                                <li>By participating in the Credit Cards Rewards Program you consent to sharing your profile information (restricted to rewards fulfillment/delivery) with our Reward fulfillment partner i.e. Accentiv India Pvt. Ltd.</li>
                                </ul>
                            </p>
                        )
                    }
                    </DisplayText>
                </HomeNotes>
            </HomeContainer>
        )
    }
}
const mapStateToProps = ({ user, catalogue, cart }) => ({
    profile: user && user.profile ? Object.values(user.profile) : null
})
const mapDispatchToProps = dispatch => ({
    clearBreadcrumbs: () => dispatch(setBreadcrumbs([])),
    toggleSidebar: (type, props) => dispatch(showSidebar(type, props))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);