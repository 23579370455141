import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { DisplayText } from '../components/display-text';
import { setBreadcrumbs, showSidebar } from '../store/actions/app';

const Section = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 1rem;
`;

const Container = styled.div`
    text-align: left;
    padding: 1rem;
`;

class TnC extends Component {
    componentDidMount() {
        const { showBreadcrumbs } = this.props;
        this.props.toggleSidebar('');
        showBreadcrumbs([{ name: 'Home', link: '/' }, { name: `Terms and Conditions` }]);
    }

    render() {
        return (
            <Container>
                <Section>
                    <DisplayText size="medium" color="#DB0011" variant="strong">Rewards Program Terms and Conditions</DisplayText>

                    <Section>
                        <DisplayText color="#333" variant="strong">ELIGIBILITY</DisplayText>
                         <DisplayText size="small" color="#333"><ul>
                                <li>The HSBC Rewards Program is open to all HSBC primary Credit Cardholders, excluding Corporate Cardholders and HSBC Cashback Credit Card.</li>
                                <li>Credit Cardholders are eligible for the program only as long as their Credit Card accounts and all linked accounts are valid and in good standing, i.e. there is no amount overdue.</li>
                                <li>The Credit Cardholder cannot transfer Reward points to another cardholder. The Credit Cardholder can, however, combine Reward points of his/her multiple HSBC Credit Cards at the time of redemption.</li>
                            </ul>
							</DisplayText>
                    </Section>
                    <Section>
                        <DisplayText color="#333" variant="strong">THE REWARDS PROGRAM</DisplayText>

                        <DisplayText size="small" color="#333">Eligible Credit Cardholders of HSBC will earn reward points as given below,
						<ul><li>HSBC Premier Credit Cardholders will earn 3 Reward points for every purchase of ₹100.</li>
						<li>HSBC Platinum Credit Cardholders will earn 2 Reward points for every purchase of ₹150.</li>
						<li>HSBC Classic Cardholders will earn 1 Reward point for every purchase of ₹250.</li>
						<li>HSBC Gold Cardholders will earn 1 Reward point for every purchase of ₹100.</li>
						<li>HSBC Smart Value Card Cardholders will earn 1 Reward point for every purchase of ₹100.</li></ul></DisplayText>
						

						 <DisplayText size="small" color="#333">
                            <ul>
                                <li>Reward point expiry will follow a rolling expiry system with a Reward point validity period of 2 years for HSBC Gold, Smart Value Card & Classic Credit Cards and 3 years for HSBC Platinum Credit Cards. If the Reward point validity on your card is 2 years, the Reward points earned during the 1st year of card membership, if not redeemed, will expire at the end of the 2nd year of card membership, Reward points earned during the 2nd year of card membership, if not redeemed, will expire at the end of the 3rd year and so on. The 1st year of card membership begins from the date of credit card issuance. For instance, for a card issued on 10 Mar 2016, the Reward points earned from 10 March 2016 to 10 March 2017, if unredeemed, will expire on 31 March 2018. </li>
                                <li>Effective 25 January 2023, Reward points for Premier card never expires. Reward points will be offered only for purchases made on the HSBC Credit Card.</li>
                                <li>Reward points will not be offered on fuel purchases where the surcharge is waived. Purchases where the surcharge is not waived will earn Reward points. Fuel surcharge waiver is applicable on fuel purchases between ₹400 and ₹4,000 at any pump in India or according to any such criteria as decided by HSBC from time to time.</li>
                                <li>Cash advances, any fees/charges and disputed transactions will not accrue Reward points.</li>
                                <li>Purchases on add-on Credit Cards will accrue Reward points which will be credited to the Primary Cardholder's Card account. Only the Primary Credit Cardholder can redeem Reward points.</li>
                                <li>For Credit Cardholders other than HSBC Premier and Platinum Credit Cardholders, all unredeemed Reward points accrued in a two year period will expire at the end of that period, the first two year period beginning on the date of credit card issuance or 15 June 2000, whichever is later.</li>
                                <li>For HSBC Platinum Credit Cardholders, the unredeemed Reward points accrued in a year will expire three years after the beginning of that year, the first year beginning on the date of Credit Card issuance or 15 June 2000, which.ever is later.</li>
                                <li>The accumulated Reward points have to be redeemed by the Credit Cardholder within one month of the end of the point accrual period, failing which the points will be dropped off from the Credit Card account.</li>								
                                <li>Following categories will not accrue Reward points on HSBC Platinum Credit Card starting from 05 August 2024: 
								<table border="1">
<tbody>
<tr  style={{textAlign: 'center',color: "white", background: "#DB0011"}}>
<td>
<p><strong>Merchant Group </strong></p>
</td>
<td style={{padding: '0px 10px 0 10px'}}>
<p><strong>MCC</strong></p>
</td>
</tr>
<tr style={{textAlign: 'center'}}>
<td style={{padding: '0px 10px 0 10px'}}>
<p>UTILITIES</p>
</td>
<td>
<p>4900</p>
</td>
</tr>
<tr style={{textAlign: 'center'}}>
<td style={{padding: '0px 10px 0 10px'}}>
<p>TAX PAYMENTS</p>
</td>
<td style={{padding: '0px 10px 0 10px'}}>
<p>9311</p>
</td>
</tr>
<tr style={{textAlign: 'center'}}>
<td style={{padding: '0px 10px 0 10px'}}>
<p>REAL ESTATE AGENT AND MANAGERS</p>
</td>
<td style={{padding: '0px 10px 0 10px'}}>
<p>6513</p>
</td>
</tr>
<tr style={{textAlign: 'center'}}>
<td style={{padding: '0px 10px 0 10px'}}>
<p>NON FINANCIAL INSTITUTIONS</p>
</td>
<td style={{padding: '0px 10px 0 10px'}}>
<p>6051</p>
</td>
</tr>
<tr style={{textAlign: 'center'}}>
<td style={{padding: '0px 10px 0 10px'}}>
<p>MONEY TRANSFER</p>
</td>
<td style={{padding: '0px 10px 0 10px'}}>
<p>4829</p>
</td>
</tr>
<tr style={{textAlign: 'center'}}>
<td style={{padding: '0px 10px 0 10px'}}>
<p>JEWELRY</p>
</td>
<td style={{padding: '0px 10px 0 10px'}}>
<p>5944, 5094</p>
</td>
</tr>
<tr style={{textAlign: 'center'}}>
<td style={{padding: '0px 10px 0 10px'}}>
<p>INSURANCE</p>
</td>
<td style={{padding: '0px 10px 0 10px'}}>
<p>6300, 5960</p>
</td>
</tr>
<tr style={{textAlign: 'center'}}>
<td style={{padding: '0px 10px 0 10px'}}>
<p>FUEL</p>
</td>
<td style={{padding: '0px 10px 0 10px'}}>
<p>5541, 5983, 5172, 5542, 5552</p>
</td>
</tr>
<tr style={{textAlign: 'center'}}>
<td style={{padding: '0px 10px 0 10px'}}>
<p>FINANCIAL INSTITUTIONS</p>
</td>
<td style={{padding: '0px 10px 0 10px'}}>
<p>6011, 6012, 6010</p>
</td>
</tr>
<tr style={{textAlign: 'center'}}>
<td style={{padding: '0px 10px 0 10px'}}>
<p>E-WALLETS</p>
</td>
<td style={{padding: '0px 10px 0 10px'}}>
<p>6540</p>
</td>
</tr>
<tr style={{textAlign: 'center'}}>
<td style={{padding: '0px 10px 0 10px'}}>
<p>EDUCATION & GOVERNMENT</p>
</td>
<td style={{padding: '0px 10px 0 10px'}}>
<p >9399, 8299, 8220, 8211, 8241, 8244, 8249, <br />9222, 9402,9211, 9405, 9223</p>
</td>
</tr>
</tbody>
</table></li>
                            </ul>
                        </DisplayText>
						
                    </Section>
                    <Section>
                        <DisplayText color="#333" variant="strong">REDEMPTION</DisplayText>
<DisplayText size="small" color="#333">
                         <ul>
                                <li>Reward points can be redeemed against, gift vouchers, charities, and airline miles by the Primary Cardholder only. Reward points cannot be exchanged for cash or credit or used to obtain cash advances or used against payments of any charges.</li>
                                <li>Handling charges for redemption of Reward points are NIL.</li>
                            </ul></DisplayText>
                    </Section>
                   
                   
                    <Section><DisplayText color="#333" variant="strong">Gift Vouchers:</DisplayText>   

                        <h4>For E-Vouchers Redemption</h4>
                           <DisplayText size="small" color="#333"><ul>
                                <li>An E-voucher is an electronic voucher which will be sent to the customer’s registered email address & mobile number as updated in HSBC record. No Physical Voucher will be sent.</li>
                                <li>Email address & mobile number is mandatory for E-voucher redemption. The use of the E-voucher is subject to the terms stated therein, E- voucher, can only be redeemed once unless otherwise specified on the Gift Voucher.</li>
                            </ul></DisplayText>
                    </Section>
                    <Section><DisplayText color="#333" variant="strong">Airline Miles:</DisplayText>   
                        <h4>InterMiles (formerly known as Jet Privilege)</h4>
                           <DisplayText size="small" color="#333"><ul>
                                <li>This facility is open to all HSBC Credit Cardholders, who are eligible for Rewards program.</li>
                                <li>All terms and conditions of HSBC's Rewards Program are applicable, unless otherwise mentioned.</li>
                                <li>For any conversion request to JPMiles, cardholder's JetPrivilege account will be credited in approximately 7 working days from the date of receiving the request. Whilst HSBC shall endeavor to execute all conversion requests within this period, HSBC shall not be liable in any way for any delay in crediting the JPMiles to member’s InterMiles account.</li>
                                <li>HSBC shall not be liable in any way for any issues related to cardholder's InterMiles membership and its associated privileges.</li>
                                <li>This facility has been included in the regular Rewards redemption options on HSBC Credit Card and can be discontinued at the sole discretion of HSBC.</li>
								<li>Jet Privilege Pvt. Ltd. is responsible for the InterMiles frequent flyer program. InterMiles are issued and redeemed in accordance to the InterMiles Mile program terms and conditions which can be found at <a href="https://www.intermiles.com/terms-and-conditions/intermiles-program-terms-and-conditions">https://www.intermiles.com/terms-and-conditions/intermiles-program-terms-and-conditions</a> </li>
                            </ul></DisplayText>
							
                        <h4>Singapore Airlines</h4>
                           <DisplayText size="small" color="#333"><ul>
                                <li>HSBC Credit Cardholders have the option of redeeming their Reward points against KrisFlyer Miles, being membership points under the Frequent Flier program run by Singapore Airlines.</li>
                                <li>The HSBC Rewards Program is open to all HSBC Credit Cardholders, excluding corporate cardholders. All terms and conditions of HSBC's Rewards Program are applicable, unless otherwise mentioned. For any conversion request to KrisFlyer Miles, the cardholder's KrisFlyer account will be credited in approximately 7 working days from the date of receiving the request. Whilst HSBC shall endeavor to execute all conversion requests within this period, HSBC shall not be liable in any way for any delay in crediting the KrisFlyer account.</li>
                                <li>HSBC shall not be liable in any way for any issues related to the cardholder's KrisFlyer membership and its associated privileges.</li>
                                <li>Singapore Airlines is responsible for the Krisflyer Miles program. Krisflyer Miles are issued and redeemed in accordance to the Krisflyer Miles terms and conditions which can be found on <a href="http://www.singaporeair.com">www.singaporeair.com</a></li>
                                <li>This facility has been included in the regular Rewards redemption options on HSBC credit card and can be discontinued at the sole discretion of HSBC.</li>
                            </ul></DisplayText>

                        <h4>British Airways</h4>
                            <DisplayText size="small" color="#333"><ul>
                                <li>HSBC Credit Cardholders have the option of converting their Reward points into Avios. “Avios” is the currency of the British Airways Executive Club. The HSBC Rewards Program is open to all HSBC Credit Cardholders, excluding corporate cardholders.</li>
                                <li>For any conversion request to Avios, the cardholder's British Airways Executive Club account will be credited in approximately 7 working days from the date of receiving the request.</li>
                                <li>HSBC shall not be liable in any way for any issues related to the cardholder’s Executive Club membership and its associated privileges and all disputes or claims will have to be taken up by the Credit Card holder against British Airways.</li>
                                <li>This facility has been included in the regular Rewards redemption options on HSBC Credit Card and can be discontinued at the sole discretion of HSBC.</li>
                                <li>British Airways is solely responsible for the British Airways Executive Club. Avios are issued and redeemed in accordance to the Executive Club terms and conditions which can be found at <a href="http://www.ba.com/avios">www.ba.com/avios</a></li>
                            </ul></DisplayText>
						<h4>Etihad Airways</h4>
                            <DisplayText size="small" color="#333"><ul>
                                <li>This facility is open to all HSBC Credit Cardholders, who are eligible for Rewards Program.</li>
                                <li>All terms and conditions of HSBC's Rewards Program are applicable, unless otherwise mentioned.</li>
                                <li>For any conversion request to Airmiles, cardholder's Eithad Airways membership account will be credited in approximately 7 working days from the date of receiving the request. Whilst HSBC shall endeavor to execute all conversion requests within this period, HSBC shall not be liable in any way for any delay in crediting the airmiles to member’s Etihad account.</li>
                                <li>HSBC shall not be liable in any way for any issues related to cardholder's Etihad membership and its associated privileges.</li>
                                <li>This facility has been included in the regular Rewards redemption options on HSBC Credit Card and can be discontinued at the sole discretion of HSBC.</li>
                                <li>Etihad Airways is responsible for the airmiles frequent flyer program. Airmiles are issued and redeemed in accordance to the Etihad Airways frequent flyer program terms and conditions which can be found at Etihad Guest | Etihad Airways' Loyalty Program.</li>
                            </ul></DisplayText>
						<h4>Flying Returns (Air India)</h4>
                           <DisplayText size="small" color="#333"> <ul>
                                <li>This facility is open to all HSBC Credit Cardholders, who are eligible for HSBC Rewards Program.</li>
                                <li>All terms and conditions of HSBC's Rewards Program are applicable, unless otherwise mentioned.</li>
                                <li>For any conversion request to Flying Return points, membership account will be credited in approximately 10 working days from the date of receiving the request. Whilst HSBC shall endeavor to execute all conversion requests within this period, HSBC shall not be liable in any way for any delay in crediting the Flying return points to member’s Flying Return membership account.</li>
                                <li>HSBC shall not be liable in any way for any issues related to cardholder's Flying Return membership and its associated privileges.</li>
                                <li>This facility has been included in the regular Rewards redemption options on HSBC Credit Card and can be discontinued at the sole discretion of HSBC.</li>
                                <li>Air India is responsible for the Flying Return program. Flying Return points are issued and redeemed in accordance to the Air India’s frequent flyer program <a href="https://www.airindia.com/in/en/flying-returns.html">Flying Returns | Air India</a></li>
                            </ul></DisplayText>
						
                    </Section>
                    <Section><DisplayText color="#333" variant="strong">CHARITIES:</DisplayText>
                           <DisplayText size="small" color="#333"><ul>
                                <li>Credit Cardholders have the option of redeeming their Reward points against donations to the Charitable Organizations / NGOs listed in the Rewards catalogue.</li>
                                <li>HSBC will transfer the donation amount to the selected charities / NGOs within 03 (three) weeks of receiving the redemption request.</li>
                                <li>Tax exemption certificates on charities will be sent directly by the charities to cardholders donating their reward points for charities. Cardholders must contact the charities directly for issues relating to their tax exemption certificates.</li>
                                <li>Any disputes arising out of such donations shall be taken up by the cardholder directly with the charities / NGOs.</li>
                            </ul></DisplayText>
                    </Section>
                   
                    <Section><DisplayText color="#333" variant="strong">REDEMPTION PROCESS:</DisplayText>
                            <DisplayText size="small" color="#333"><ul>
                                <li>Credit cardholders can visit the <a href="https://www.creditcardrewards.hsbc.co.in/www.hsbc.co.in">HSBC website</a> or use the PhoneBanking facility or will have to fill in the redemption form and submit to the nearest HSBC India Branch.</li>
                                <li>Redemption requests will not be serviced if the required details are not correctly provided.</li>
                                <li>Credit Cardholders cannot redeem their Reward points against outstanding balance on their HSBC Credit Card.</li>
                                <li>Redemption of Gift Vouchers is subject to availability.</li>
                            </ul></DisplayText>
                    </Section>
                    <Section><DisplayText color="#333" variant="strong">FULFILLMENT :</DisplayText>
                           <DisplayText size="small" color="#333"><ul>
                                <li>Any redemption request received by HSBC will be fulfilled within three weeks’ time from the date of receipt. E-vouchers options will be delivered on customer’s registered Email and Mobile number via SMS and will be fulfilled within 3 working days from date of receipt.</li>
                                <li>By participating in the Credit Cards Rewards Program, you consent to sharing your profile information (restricted to rewards fulfillment/delivery) with our Reward fulfillment partner i.e. Accentive India Pvt. Ltd.</li>
                                <li>All Rewards delivery will be fulfilled by the said partner at the registered communication details updated with the bank.</li>
                                <li>The estimated time for dispatch and delivery of products is 15 working days from the date of order confirmation.</li>
                                <li>In case of damaged or defective products, the customer must report this to the bank within 10 working days from the date of delivery.</li>
                                <li>You will receive Email communication from email id – HSBC Credit Cards Rewards <a href="mailto:noreply.accentivassist-IN@accentiv.co.in">(noreply.accentivassist-IN@accentiv.co.in) </a>You will receive SMS communication from sender id – HSBCRW</li>
                            </ul></DisplayText>
                    </Section>
                    <Section><DisplayText color="#333" variant="strong">WITHDRAWAL :</DisplayText>
                          <DisplayText size="small" color="#333"> <ul>
                                <li>HSBC reserves the right to modify the Rewards Program wholly or partly. HSBC also reserves the right to change the Reward point conversion rate, withdraw Reward points awarded or to vary any of the terms and conditions herein in its absolute discretion and without prior notice to Credit Cardholders.</li>
                                <li>In case this Rewards Program comes in conflict with any rule, regulation, or order of any statutory authority, then HSBC has absolute authority and right to modify or cancel this program to give effect to the said requirements.</li>
                            </ul></DisplayText>
                    </Section>
                    <Section><DisplayText color="#DB0011" variant="strong">General Terms and conditions</DisplayText>
                            <DisplayText size="small" color="#333"><ol>
<li>Commitment of any fraud and abuse relating to earning and redemption of reward points in the Rewards Program, will result in forfeiture of accrued points as well as termination of the credit cardholder's credit card account.</li>
<li>Subject to discretion, information supplied by the Credit Cardholder on the redemption of certain products/services may be used by HSBC or its service establishments for administrative or<br />marketing purposes.</li>
<li>The Rewards Program is entirely voluntary, and it is understood that all charges are voluntarily incurred by the credit cardholder in the normal course of credit card usage.</li>
<li>Any tax or other liabilities or charges payable to the Government or any other authority or body or any other participating establishment which may arise or accrue to the HSBC Credit Cardholders by redemption as aforesaid or otherwise as result of this Rewards Program shall be borne by the HSBC Credit Cardholder.</li>
<li>Terms and conditions stated herein do not amount to any commitment or representation by HSBC to conduct such programs.</li>
<li>Selection of the products / services offered under the Rewards Program will be at the sole discretion of HSBC and are liable to change without notice.</li>
<li>HSBC assumes no responsibility for the Reward points converted into a participating partner's loyalty program or for actions of any participating merchant or any other partner in connection with the Rewards program or otherwise.</li>
<li>In the event of the HSBC Credit Card being voluntarily closed by a Credit Cardholder or by the bank, the Reward points that are in the Credit Card account cannot be redeemed. In case the Credit Cardholder fails to redeem these points before closing the credit card, the Reward points will automatically lapse.</li>
<li>In case of a disputed transaction being resolved in the favour of the credit cardholder and/or where a transaction is reversed, the equivalent Reward points will also be reversed.</li>
<li>Reward points which have been credited or debited to or from the Credit Cardholder's account shall be reflected in the following month's statement issued by HSBC to the credit cardholder.</li>
<li>On redemption, the Reward points will automatically be subtracted from the accumulated points in the credit cardholder's credit card account.</li>
<li>Redemption orders once given to HSBC cannot be cancelled, withdrawn, or changed. Redeemed rewards are not exchangeable for other rewards or refundable, replaceable, or transferable for cash or credit under any circumstances. Reward points once exchanged for any other partner's loyalty program cannot be transferred or cancelled.</li>
<li>HSBC will not be held responsible if any supplier of products / services offered to credit cardholders withdraws, cancels, alters or amends those products / services.</li>
<li>HSBC makes no warranties for the quality of goods or services provided by the merchant establishments participating in the Rewards Program.</li>
<li>HSBC will not be responsible for lost or stolen coupons / vouchers / certificates redeemed through the Rewards Program. HSBC and its agents are not responsible for any orders not received, due to correspondence lost or delayed in the mail.</li>
<li>HSBC shall not be liable for any loss or damage whatsoever that may be suffered, or for any personal injury that may be suffered as a result of availing of the benefits under the program.</li>
<li>HSBC reserves its absolute right to withdraw or alter any of the terms and conditions of the Reward Program at any time without prior intimation to the cardholders.</li>
<li>HSBC's decision on computation of Reward points on the credit card account will be final, conclusive and binding on the credit cardholder and will not be liable to be disputed or questioned.</li>
<li>The existence of a dispute, if any, with a third party shall not constitute a claim against HSBC.</li>
<li>Any disputes arising out of this program shall be subject to the exclusive jurisdiction of the competent courts in Mumbai only.</li>
<li>Please note that basis Goods Service Tax (GST) regulations and notified GST rates, Central GST and State/Union Territory GST or Inter-State GST, as applicable, would apply on our fees and charges with effect from 01 July 2017.</li>
<li>All queries / disputes arising out of this program shall be referred to HSBC PhoneBanking.</li>
</ol></DisplayText>
                    </Section>
					
					<Section>
                        <DisplayText color="#333" variant="strong">Issued by The Hongkong and Shanghai Banking Corporation Limited, India. Incorporated in Hong Kong SAR with limited liability.</DisplayText>
 <DisplayText color="#333" variant="strong">   <a href="https://www.hsbc.co.in/privacy-statement/">Privacy and Security</a> | <a href="https://www.hsbc.co.in/site-terms/">Terms of use</a> | <a href="https://www.hsbc.co.in/hyperlink-policy/">Hyperlink Policy</a></DisplayText>
 <DisplayText color="#333" variant="strong">© Copyright 2024. The Hongkong and Shanghai Banking Corporation Limited, India. Incorporated in Hong Kong SAR with limited liability. All rights reserved.</DisplayText>
</Section>
				</Section>
            </Container>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    showBreadcrumbs: (paths) => dispatch(setBreadcrumbs(paths)),
    toggleSidebar: (type, props) => dispatch(showSidebar(type, props))
})

export default connect(null, mapDispatchToProps)(TnC);
